import React, { Component } from 'react';
import { connect } from 'react-redux';

class AccountModal extends Component {
  render() {
    return (
      <div className='acct-modal'>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {}
}

export default connect(mapStateToProps)(AccountModal);