import React, { Component } from 'react';
import { connect } from 'react-redux';

class Header extends Component {
  render() {
    return (
      <div className='header'>
        <div className='content-container'>
          <div className="col-md-4 site-title">Rxven.io</div>
          <div className="col-md-3"></div>
          <div className="col nav-menu">
            <ul>
              <li className='nav-item'>Projects</li>
              <li className='nav-item'>About Us</li>
              <li className='nav-item'>Contact Us</li>
            </ul>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {}
}

export default connect(mapStateToProps)(Header);