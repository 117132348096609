import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
const axios = require('axios');

class PayModal extends Component {
  onSubmit(values) {
    axios.post('/pay', values)
    .then(function (response) {
      console.log(response);
    })
    .catch(function (error) {
      console.log(error);
    });
  }

  render() {
    return (
      <Form onSubmit={(values) => this.onSubmit(values)}>
          {({ handleSubmit, form }) => (
          <form onSubmit={handleSubmit}>
            <div>
              <label>Pay From:</label>
              <Field name="pay_from" component="select">
                <option />
                <option value="checking1">Checking 1</option>
                <option value="checking2">Checking 2</option>
                <option value="savings1">Savings 1</option>
              </Field>
            </div>
            <div>
              <label>Pay To:</label>
              <Field
                name="pay_to"
                component="input"
                type="text"
                placeholder="Recipient"
              />
            </div>
            <div>
              <label>Amount</label>
              <Field
                name="amount"
                component="input"
                type="number"
                placeholder="0.00"
              />
            </div>
            <div className="buttons">
              <button type="submit">
                Submit
              </button>
              <button
                type="button"
                onClick={form.reset}
              >
                Reset
              </button>
            </div>
          </form>
        )}
      </Form>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {}
}

export default connect(mapStateToProps)(PayModal);