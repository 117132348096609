import React, { Component } from 'react';
import { connect } from 'react-redux';

class Contact extends Component {
  render() {
    return (
      <div className='contact'>
        <div className='content-container tight'>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {}
}

export default connect(mapStateToProps)(Contact);