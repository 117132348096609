import React, { Component } from 'react';
import { connect } from 'react-redux';

class About extends Component {
  render() {
    return (
      <div className='about'>
        <div className='content-container tight no-flex'>
            <div className='section-title'>
                <h1>About Us</h1>
                <p className='white-text'>A platform made out of necessity after years of dealing with the same issues on the same APIs in different teams.</p>
            </div>
            <div className='icon-bank'>
                <div className='icon-set col-md-6 flex-wrap'>
                    <div className='icon-circle'><span class="material-icons text-gradient">accessible_forward</span></div>
                    <h2>Adam</h2>
                    <p>Please send help.</p>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {}
}

export default connect(mapStateToProps)(About);