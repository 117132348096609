import React, { Component } from 'react';
import { connect } from 'react-redux';
import keyToComponent from '../../util/keyToComponent';

class Content extends Component {
  render() {
    return (
      <div className='root'>
        { this.props.components.map(keyToComponent) }
      </div>
    );
  }
}

function getRoute(acc, split) {
  if (acc.routes[split] !== undefined)
    return { ...acc, routes: acc.routes[split] };
  else if (acc.routes[":key"] !== undefined)
    return { keys: [ ...acc.keys, split ], routes: acc.routes[":key"] };
  return { components: [] };
}

export default connect((state) => {
  const route = state.router.location.pathname.split('/').slice(1);
  
  let result =
    route.reduce(getRoute, { keys: [], routes: state.routes })

  return {
    keys: result.keys,
    components: result.routes._meta.components
  };
})(Content);
