import React from 'react';

import RxvenHeader from '../components/rxven/header';
import Hero from '../components/odin/hero';
import About from '../components/odin/about';
import Projects from '../components/odin/projects';
import Contact from '../components/odin/contact';
import PayModal from '../components/njord/pay_modal';
import AccountModal from '../components/njord/account_modal';

function getComponent({ component, instance }, i) {
  switch (component) {
    case "rxven_header":
      return <RxvenHeader instance={instance} key={i} />;
    case "odin_hero":
      return <Hero instance={instance} key={i} />;
    case "odin_about":
      return <About instance={instance} key={i} />;
    case "odin_projects":
      return <Projects instance={instance} key={i} />;
    case "odin_contact":
      return <Contact instance={instance} key={i} />;
    case "account_modal":
      return <AccountModal instance={instance} key={i} />;
    case "pay_modal":
      return <PayModal instance={instance} key={i} />;
    
    default:
      return <div className="Invalid component" id={component} key={i} />
  }
}

export default getComponent;