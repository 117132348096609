const initialState = {
  "": {
    _meta: {
      components: [
        { component: "rxven_header", instance: "root" },
        { component: "odin_hero", instance: "root" },
        { component: "odin_projects", instance: "root" },
        { component: "odin_about", instance: "root" },
        { component: "odin_contact", instance: "root" }
      ],
      title: "Rxven | Index"
    }
  },
  lockbox: {
    _meta: {
      components: [],
      title: "Rxven | Lockbox"
    },
    ":key": {
      _meta: {
        components: [],
        title: "Rxven | Lockbox Record View"
      },
      update: {
        _meta: {
          components: [],
          title: "Rxven | Update Lockbox Record"
        }
      }
    }
  },
  "account-modal": {
    _meta: {
      components: [
        { component: "account_modal", instance: "root" }
      ],
      title: "Rxven | Account Modal"
    }
  },
  "pay-modal": {
    _meta: {
      components: [
        { component: "pay_modal", instance: "root" }
      ],
      title: "Rxven | Pay Modal"
    }
  }
}

function routes(state = initialState, action) {
  switch (action.type) {
    
    default:
      return state;
  }
}

export default routes;