import { createStore, applyMiddleware, compose } from 'redux'
import rootReducer from './reducer'
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

export const history = createBrowserHistory();
const store = createStore(
  rootReducer(history),
  compose(
    applyMiddleware(
      routerMiddleware(history)
    )
  )
);
export default store;