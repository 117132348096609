import React, { Component } from 'react';
import { connect } from 'react-redux';

class Projects extends Component {
  render() {
    return (
      <div className='projects'>
        <div className='content-container tight no-flex'>
            <div className='section-title'>
                <h1 className="text-gradient">Powerful. Scalable. Secure.</h1>
                <p>Products made on the platform gain access to a continuously growing set of tools, allowing them world-class performance and security at a fraction of the development cost.</p>
            </div>
            <div className='icon-bank'>
                <div className='icon-set col-md-4'>
                    <div className='icon-circle'><span class="material-icons text-gradient">verified_user</span></div>
                    <h2>Odin</h2>
                    <p>A powerful Authentication/Authorization and User Management system.</p>
                </div>
                <div className='icon-set col-md-4'>
                    <div className='icon-circle'><span class="material-icons text-gradient">water_drop</span></div>
                    <h2>Njord</h2>
                    <p>A white-labeled transaction processing system aimed at businesses.</p>
                </div>
                <div className='icon-set col-md-4'>
                    <div className='icon-circle'><span class="material-icons text-gradient">directions_boat</span></div>
                    <h2>Knarr</h2>
                    <p>An online shopping platform with an emphasis on payments and logistics.</p>
                </div>
                <div className='icon-set col-md-4'>
                    <div className='icon-circle'><span class="material-icons text-gradient">task</span></div>
                    <h2>Jupiter</h2>
                    <p>An agile management system focusing on individual tracking and growth.</p>
                </div>
                <div className='icon-set col-md-4'>
                    <div className='icon-circle'><span class="material-icons text-gradient">cottage</span></div>
                    <h2>Terminus</h2>
                    <p>A property management system that automates recurring tasks.</p>
                </div>
                <div className='icon-set col-md-4'>
                    <div className='icon-circle'><span class="material-icons text-gradient">link</span></div>
                    <h2>Ares</h2>
                    <p>A tournament system providing statistically sound brackets.</p>
                </div>
            </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {}
}

export default connect(mapStateToProps)(Projects);