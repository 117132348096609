import React, { Component } from 'react';
import { connect } from 'react-redux';

class Hero extends Component {
  render() {
    return (
      <div className='hero'>
        <div className='content-container'>
            <div className='hero-text'>
                <h1 className='hero-h1'>Process Payments <br /> with Njord</h1>
                <p>A development platform built on best practices and innovative ideas meant to tackle a vast array of problems with new approaches to proven solutions.</p>
            </div>
            <div className='hero-widget'>
            </div>
        </div>
        <div className='diagonal-box1' />
        <div className='diagonal-box2' />
        <div className='diagonal-box3' />
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  return {}
}

export default connect(mapStateToProps)(Hero);